<template>
  <div>
    <el-container>
      <el-header style="padding: 0" height="190px">
        <template>
          <heard></heard>
        </template>
      </el-header>
      <el-main style="padding: 0">
        <template>
          <div style="border-top: 10px solid deepskyblue;width: 100%">
            <carousel :images="images" height="450px"></carousel>
          </div>
        </template>
        <template>
          <div style="background-color: #eeeeee;height: 50px" class="basic_container_noflex">
            <ul style="list-style: none;display: flex;flex-direction: row;line-height: 50px"
                class="basic_container_noflex">
              <li :class="{active_tag:active===i}" class="active_bg" style="width: 120px" v-for="(item,i) in child_menu"
                  :key="i" @click="go_change(i,item)">{{ item.itemText }}
              </li>
            </ul>
          </div>
        </template>
        <template v-if="showPage">
          <div class="basic_container scroll" style="margin-top: 50px;height: 800px;overflow-y:scroll">
            <product_item :product-basic-info="productBasicInfo" @clickDetail="goDetail"></product_item>
          </div>
          <el-pagination
              :background="background"
              :current-page.sync="currentPage"
              :page-size.sync="pageSize"
              :layout="layout"
              :page-sizes="pageSizes"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </template>
        <template v-if="!showPage">
          <product-detail ref="productDetail"></product-detail>
        </template>
        <template>
          <top-tag></top-tag>
        </template>
        <template>
          <top-message></top-message>
        </template>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Heard from "@/components/Heard";
import Carousel from "@/components/Carousel";
import TopTag from "@/components/TopTag";
import Product_item from "@/components/product_item";
import TopMessage from "@/components/TopMessage";
import {httpAction} from "@/api/manage";
import ProductDetail from "@/views/product/productDetail";
import {isTelPhone} from "@/utils/validate";

export default {
  name: "index",
  components: {
    ProductDetail,
    TopMessage,
    Product_item,
    Heard,
    Carousel,
    TopTag
  },
  props: {
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    pageSizes: {
      type: Array,
      default() {
        return [15, 30, 50, 75, 100];
      }
    },
  },
  data() {
    return {
      showPage: true,
      indexView: {},
      currentPage: 1,
      pageSize: 12,
      total: 0,
      logoImage: require("@/assets/com_logo.jpg"),
      compcty_name: "四川锦荣科技",
      compcty_name_en: "SICHUANJINRRONGKEJI",
      active: 0,
      child_menu: [],
      images: [],
      companyInfo: {},
      label_items: [],
      productBasicInfo: [],
      urls: {
        menu: "/dictItemManagement/list",
        productList: "/productManage/pageProductBasicList",
        initIndex: "/index/getIndexConfig",
        companyInfo: "/companyManage/getCompanyInfo"
      },
      currentType: ""
    }
  },
  created() {
    httpAction(this.urls.initIndex, "", "get").then(res => {
      if (res.code == 0) {
        Object.assign(this.indexView, res.data)
        if (this.indexView.indexImages) {
          this.images = this.indexView.indexImages.map((item, index) => {
            return item.fileUrl
          })
        }
      }
    })
    this.initView()
    if (this.$route.query.id) {
      setTimeout(() => {
        this.goDetail({id: this.$route.query.id})
      }, 500)
    }
  },
  methods: {
    initView() {
      this.showPage = true
      httpAction(this.urls.menu, {
        body: {
          dictCode: "product_catalog",
          status: 1,
        },
        header: {
          sortList: [{
            orderField: "sortNo",
            orderType: 'ASC'
          }]
        }
      }).then(res => {
        if (res.code == 0) {
          this.child_menu = Object.assign({}, res.data)
          this.go_change(0, this.child_menu[0])
        }
        return httpAction(this.urls.companyInfo, {body: {}});
      }).then(res1 => {
        if (res1.code == 0) {
          Object.assign(this.companyInfo, res1.data)
          this.loadLabelItems()
        }
      })
    },
    go_change(i, item) {
      httpAction(this.urls.productList, {
        body: {
          type: item.id
        },
        header: {
          sortList: [{
            orderField: "updateTime",
            orderType: 'desc'
          }]
        }
      }).then(res => {
        if (res.code == 0) {
          this.productBasicInfo = res.data.dataList
          this.total = +res.data.total
        }
      })
      this.active = i
      this.currentType = item.id
      setTimeout(() => {
        this.showPage = true
      }, 200)

    },
    loadLabelItems() {
      this.label_items = [
        {
          text: "ADDRESS",
          text_Context: this.companyInfo.companyAddress,
          icon: require("@/assets/address_icon.png"),
          border_left: true,
        }, {
          text: "CALL US",
          text_Context: "电话:" + this.companyInfo.companyPhone,
          icon: require("@/assets/phone_icon.png"),
          border_left: true,
        },
        {
          text: "EMAIL",
          text_Context: this.companyInfo.companyEmail,
          icon: require("@/assets/email_icon.png"),
          border_left: false,
        }
      ]
    },
    pageChange(pageVal) {
      httpAction(this.urls.productList, {
        body: {
          type: this.currentType
        },
        header: {
          pageNumber: pageVal.page,
          pageSize: pageVal.limit,
          sortList: [{
            orderField: "updateTime",
            orderType: 'desc'
          }]
        }
      }).then(res => {
        if (res.code == 0) {
          this.productBasicInfo = res.data.dataList
        }
      })
      this.pageSize = pageVal.pageSize
      this.currentPage = pageVal.page
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.pageChange({page: val, limit: this.pageSize});
      this.pageChange()
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    goDetail(basicItem) {
      this.showPage = false;
      setTimeout(() => {
        this.$refs.productDetail.showDetailPage(basicItem)
        scrollTo(0, 800);
      }, 500)
      scrollTo(0, 800);
    }
  }
}
</script>

<style scoped>
.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.active_bg {
  cursor: pointer;
  color: black;
  font-weight: bold;
}

.active_tag {
  background-image: url("http://static.yun300.cn/img/dh1.jpg?tenantId=101184&viewType=1");
  color: white;
}

.active_bg:hover {
  background-image: url("http://static.yun300.cn/img/dh1.jpg?tenantId=101184&viewType=1");
  color: white;
}

.scroll::-webkit-scrollbar {
  width: 2px;
}

.border_left_line {
  border-right: 1px solid gray;
}
</style>
