<template>
  <div class="basic_container_noflex">
    <div>
      <div style="position: relative;width: 100%; text-align: left;">
        <el-image v-for="(item,index) in this.detailInfo.productImgs" :preview-src-list="srcAllFile" fit="cover" style="height: 200px;width: 20%;margin: 10px 5px"
                  :src="item.fileUrl" :key="index"></el-image>
      </div>
      <div style="background-color: #dad7d7 "  v-if="this.detailInfo.context">
        <p style="background-color: #cbcdd2;padding: 10px 20px;font-size: 12px;margin-bottom: 0;width: 50px">产品描述</p>
        <i style="border-top: black 1px solid;width: 100%;position: absolute;left: 0"></i>
        <div style="height: 200px;margin-left: 50px;">
          <p style="text-align: left;font-size: 24px;font-weight: bold;color: #c4c1c1;margin-bottom: 10px">BASALT
            FIBER</p>
          <p style="text-align: left;font-size: 26px;font-weight: bold;margin-top: 0;">
            {{ this.detailInfo.name }}</p>
          <div style="margin-left: 50px;margin-top: 0;left: 100px;position: relative;top: -62px">
            <p style="text-align: left;margin: 0;font-weight: bold;font-size: 16px">{{this.detailInfo.allName}}</p>
            <p style="text-align: left;margin:0;font-weight: bold;font-size: 16px">{{this.detailInfo.enName}}</p>
          </div>
          <p style="text-align: left;position: absolute;width: 1030px;height: 100px;margin-top: -30px">
           {{this.detailInfo.context}}</p>
        </div>
      </div>
    </div>
      <div v-for="(item,index) in this.detailInfo.productDetail" style="position: relative;background-color: #dad7d7 ">
        <el-divider></el-divider>
        <div style="margin-left: 50px">
        <p style="text-align: left;font-size: 20px;font-weight: bold">{{item.detailTitle}}</p>
        <div>
          <p style="text-align:left" v-html="item.productContext"></p>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
import {httpAction} from "@/api/manage";

export default {
  name: "productDetail",
  data() {
    return {
      urls: {
        getProductDetail: "/productManage/findDetailById"
      },
      detailInfo: {
        context: "",
        id: "",
        indexImage: "",
        isIndex: "1",
        name: "",
        price: null,
        productDetail: [],
        productImgs: [],
      }
    }
  },
  created() {
  },
  computed:{
    srcAllFile(){
      let temp=[]
      this.detailInfo.productImgs.forEach(item=>{
       temp.push(item.fileUrl)
     })
      return temp
    }
  },
  methods: {
    showDetailPage(item) {
      httpAction(this.urls.getProductDetail, {body: item.id}).then(res => {
        this.detailInfo = res.data
      })
    },
  }
}
</script>

<style scoped>
.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.scroll::-webkit-scrollbar {
  width: 2px;
}
</style>
